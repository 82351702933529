import striptags from "striptags"

const HTML_ALLOWED_TAGS = [
	'a',
	'b',
	'br',
	'em',
	'i',
	'p',
	'small',
	'strong',
]

const KIOSK_ALLOWED_TAGS = [
// no open links in kiosk mode!
	'b',
	'br',
	'em',
	'i',
	'p',
	'small',
	'strong',
]


export const sanitize = (
	html: string,
	allowedTagsOverride?: string[],
) => striptags(html, ...[allowedTagsOverride || HTML_ALLOWED_TAGS])

export const kioskSanitize = (html: string) => sanitize(
	html,
	KIOSK_ALLOWED_TAGS
)
