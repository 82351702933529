import { getTitleSize } from "helpers/collection";
import styles from "./object-title.module.scss";
import LegacyOrMarkdownParser from "components/rich-text/legacy-or-markdown-parser";
const ObjectTitle = ({
  title
}: {
  title: string;
}) => {
  const titleSize = getTitleSize(title);
  return <h1 className={`${styles.title} ${styles[titleSize]}`} data-sentry-component="ObjectTitle" data-sentry-source-file="index.tsx">
			<LegacyOrMarkdownParser data-sentry-element="LegacyOrMarkdownParser" data-sentry-source-file="index.tsx">{title}</LegacyOrMarkdownParser>
		</h1>;
};
export default ObjectTitle;