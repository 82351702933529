"use client";

import { MouseEventHandler, useEffect, useState } from "react";
import styles from "./object-viewer.module.scss";
import ObjectBasics from "components/collection/object-basics";
import CloseButton from "assets/icons/close-x.svg";
import ChevronL from "assets/icons/chevron-l.svg";
import ObjectViewerThumbnails from "components/collection/object-viewer-thumbnails";
import type { IArtworkMinimum } from "@metmuseum/types";
const ObjectViewer = ({
  objects,
  initialActiveObjectIndex,
  packageID,
  handleCloseClick
}: Props) => {
  const [activeObjectIndex, setActiveObjectIndex] = useState(initialActiveObjectIndex);
  const handleForwardClick = () => setActiveObjectIndex(activeObjectIndex + 1);
  const handlePrevClick = () => setActiveObjectIndex(activeObjectIndex - 1);
  const prevDisabled = activeObjectIndex === 0;
  const forwardDisabled = activeObjectIndex === objects.length - 1;
  const handleThumbnailClick = e => {
    const newActiveObject = parseInt(e.currentTarget.dataset?.index);
    setActiveObjectIndex(newActiveObject);
  };
  const handleOutsideClick = e => {
    if (e.target === e.currentTarget) {
      handleCloseClick(e);
    }
  };
  useEffect(() => {
    const handleKeyDown = e => {
      e = e || window.event;
      if (e.keyCode == '37') {
        !prevDisabled && setActiveObjectIndex(activeObjectIndex - 1);
      } else if (e.keyCode == '39') {
        !forwardDisabled && setActiveObjectIndex(activeObjectIndex + 1);
      } else if (e.key == 'Escape') {
        handleCloseClick(e);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [prevDisabled, forwardDisabled, activeObjectIndex, handleCloseClick]);
  return <div className={styles.backdrop} onClick={handleOutsideClick} data-sentry-component="ObjectViewer" data-sentry-source-file="index.tsx">
			<div className={styles.buttons} onClick={handleOutsideClick}>
				<button aria-label="Previous Object" disabled={prevDisabled} onClick={handlePrevClick} className={`${styles.back} ${styles.arrow}`}>
					<ChevronL data-sentry-element="ChevronL" data-sentry-source-file="index.tsx" />
				</button>
				<button aria-label="Next Object" disabled={forwardDisabled} onClick={handleForwardClick} className={`${styles.forward} ${styles.arrow}`}>
					<ChevronL data-sentry-element="ChevronL" data-sentry-source-file="index.tsx" />
				</button>
				<button aria-label="Close Modal" className={styles.close} onClick={handleCloseClick}>
					<CloseButton data-sentry-element="CloseButton" data-sentry-source-file="index.tsx" />
				</button>
			</div>
			<div className={styles.modal}>
				<ObjectBasics key={objects[activeObjectIndex].objectID} object={objects[activeObjectIndex]} packageID={packageID} data-sentry-element="ObjectBasics" data-sentry-source-file="index.tsx" />
			</div>
			{objects?.length > 1 && <ObjectViewerThumbnails objects={objects} activeObjectIndex={activeObjectIndex} handleClick={handleThumbnailClick} />}
		</div>;
};
interface Props {
  handleCloseClick: MouseEventHandler;
  initialActiveObjectIndex: number;
  objects: IArtworkMinimum[];
  packageID?: number | null;
}
export default ObjectViewer;