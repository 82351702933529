const ArtistCultureDisplayer = ({
  object,
  threshold = 2
}) => {
  if (object.allArtistsFormatted?.length > threshold) {
    return "Multiple artists/makers";
  } else if (object.allArtistsFormatted?.length > 0) {
    return object.allArtistsFormatted?.map(artist => <div key={artist}>{artist}</div>);
  } else if (object.artists?.primary?.displayName) {
    return object.artists?.primary?.displayName;
  } else if (object.artist) {
    return object.artist;
  } else if (object.culture) {
    return object.culture;
  }
};
export default ArtistCultureDisplayer;