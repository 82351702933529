import React, { ReactNode } from 'react';
import styles from "./callOutBox.module.scss";
export const CallOutBox = ({
  children
}: IProps) => {
  if (!children) {
    return null;
  }
  return <span className={styles.box} data-sentry-component="CallOutBox" data-sentry-source-file="index.tsx">
			{children}
		</span>;
};
interface IProps {
  children: ReactNode | ReactNode[];
}
export default CallOutBox;