/* eslint-disable @next/next/no-img-element */
import Shelf from "components/shelf";
import { MouseEventHandler, useEffect, useRef } from "react";
import styles from "./styles.module.scss";
import NoImage from "assets/icons/no-image.svg";
const ObjectViewerThumbnails = ({
  objects,
  activeObjectIndex,
  handleClick
}: Props) => {
  const activeRef = useRef<any>();
  useEffect(() => {
    activeRef.current.scrollIntoView({
      inline: "center"
    });
  }, [activeObjectIndex, activeRef]);
  return <div className={styles.wrapper} data-sentry-component="ObjectViewerThumbnails" data-sentry-source-file="index.tsx">
			<Shelf style={{
      gap: "var(--spacing-micro)",
      marginBottom: "var(--spacing-s)"
    }} data-sentry-element="Shelf" data-sentry-source-file="index.tsx">
				{objects.map((object, index) => <button aria-label={object.title} ref={activeObjectIndex === index ? activeRef : null} data-index={index} className={styles.button} disabled={activeObjectIndex === index} onClick={handleClick} key={object.objectID}>
						{object.image ? <img alt={object.imageAlt} src={object.image} className={styles.thumbnail} /> : <NoImage className={styles.noImage} />}
					</button>)}
			</Shelf>
		</div>;
};
interface Props {
  handleClick: MouseEventHandler<HTMLButtonElement>;
  activeObjectIndex: number;
  objects: any[]; //TODO object
}
export default ObjectViewerThumbnails;