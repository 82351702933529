import { IArtworkMinimum } from "@metmuseum/types";
import { sanitize, kioskSanitize } from "lib/sanitizeHtml";
import type { JSX } from "react"; // for React.JSX.Element type
import RichTextInline from "components/rich-text/rich-text-inline-only";
//TODO: make contextualLabels safe for kiosk mode, too: https://jira.metmuseum.org/browse/DIGAPPDEV-2577

const chooseLabel = (object: IArtworkMinimum, packageID: string | number): string | JSX.Element => {
  const contextualLabel = object?.contextualLabel && <RichTextInline value={object.contextualLabel.label} />;
  const exhibitionLabel = object?.exhibitionLabels?.find(label => label.collectionID == packageID)?.label;
  const webLabel = object?.webLabel;
  return contextualLabel || exhibitionLabel || webLabel || "";
};
export const ObjectLabel = ({
  object,
  packageID,
  isKioskMode = false
}: Props) => {
  const label = chooseLabel(object, packageID);
  if (typeof label === "string") {
    const sanitizedLabel = isKioskMode ? kioskSanitize(label) : sanitize(label);
    return <div dangerouslySetInnerHTML={{
      __html: sanitizedLabel
    }} />;
  }
  return label;
};
interface Props {
  object: IArtworkMinimum;
  packageID: string | number;
  isKioskMode?: boolean;
}
export default ObjectLabel;