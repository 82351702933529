import fetchWithErrorLogging from "lib/fetchWithErrorLogging";
import { COLLECTION_API_OBJECT_ENDPOINT, COLLECTION_API_OBJECT_PAGE_ENDPOINT, IMPERVA_SAFELIST_SECRET } from "lib/constants.mjs";
import { IArtworkMinimum } from "@metmuseum/types";

type ArtWorkObject = {
	objectID: number,
	[key: string]: any;
}

const getHeaders = () => new Headers({
	'x-the-met-safelist': IMPERVA_SAFELIST_SECRET,
	'Authorization': `Bearer ${process.env.COLLECTION_API_AUTH_TOKEN}`
});

export const getObjectPagePath = (objectID: number, packageID: number): string => {
	const objectPagePath = `/art/collection/search/${objectID}`;
	return packageID ? `${objectPagePath}?pkgids=${packageID}` : objectPagePath;
}

export const getCollectionData = async (id: number) => {
	const data = await fetchWithErrorLogging(`${COLLECTION_API_OBJECT_ENDPOINT}/${id}`, {
		headers: getHeaders()
	});
	return data;
}

export const getObjectPageData = async (id: number) => {
	const data = await fetchWithErrorLogging(`${COLLECTION_API_OBJECT_PAGE_ENDPOINT}/${id}`, {
		headers: getHeaders()
	});
	return data;
}

export const getCollectionImages = async (id: number) => {
	const obj = await getCollectionData(id);
	return obj?.media?.images;
}

//Make a method of Artwork Model when we make that an object
const generateUrl = (object: ArtworkFromOneOfFortyDifferentAPIs, packageID: number) => {
	if (object.artworkURL) { 
		return object.artworkURL
	} else if (object.objectID) {
		return getObjectPagePath(object.objectID, packageID)
	} else if (object.ObjectID) {
		return getObjectPagePath(object.ObjectID, packageID)
	} else if (object.url) {
		return new URL(object.url).pathname
	} else {
		return ""
	}
}


//This is like duct-tape mapping completely differnetly named fields from different APIs into something that works with our FE components.
export const lofiArtworkObjectModel = (object: ArtWorkObject, packageID?: number): IArtworkMinimum => {
	return {
		accessionNumber: object.accessionNumber,
		allArtistsFormatted: object.allArtistsFormatted || object?.artists?.all?.map(x => x.displayName) || [],
		contextualLabel: object?.contextualLabel,
		creditLine: object.ceditLine,
		culture: object.culture,
		date: object.date || object.dated,
		dimensions: object.dimensions,
		exhibitionLabels: object.exhibitionlabels,
		image: object.image || object.primaryImage || object.imageURL || object.images?.primary?.mainImageUrl || object.PrimaryImage?.MainImage,
		imageAlt: object.imageAlt || object.primaryImageAlt || object.images?.primary?.altText || null,
		medium: object.medium,
		objectID: object.objectID || object.objectId,
		primaryArtist: object.primaryArtist || object?.artists?.primary?.[0]?.displayName || object.artist || object.allArtistsFormatted?.[0],
		primaryImageRestricted: object.primaryImageRestricted || !object.images?.primary?.openAccess && !!object.images?.primary?.mainImageUrl,
		title: object.title || object.titles?.primary?.title,
		url: generateUrl(object, packageID),
		webLabel: object.webLabel,
	}
}


// there is a list of objects where we shouldn't display loan info for
// only if they are in object packages of 268 or 793 
// (collectionIDs array has the object package IDs this item is in)
// and is also a returned loan (objectStatusID of 12)
export const shouldShowLoanInfo = (collectionIDs: number[], objectStatusID: number) => {
	// Check if objectStatusID is 12
	if (objectStatusID === 12) {
		// Check if collectionIDs array contains either 268 or 793
		const hasTargetIDs = collectionIDs.includes(268) || collectionIDs.includes(793);
		return !hasTargetIDs;
	}
	return true;
};

//custom title class based on word count
export const getTitleSize = (title: string): string => {
	const wordCount = title.split(' ').length;

	if (wordCount > 20 && wordCount < 34) {
		return 'longTitle';
	} else if (wordCount >= 34) {
		return 'veryLongTitle';
	} else {
		return 'regularTitle';
	}
}

//custom label class based on title size
export const getLabelMaxLines = (titleSize) => {
	switch (titleSize) {
		case 'longTitle':
			return 8;
		case 'veryLongTitle':
			return 6;
		default:
			return 10;
	}
}

//objects with objectStatusID of 0, 5, 12, 13 are loans to the Met (both currently displayed and returned)
export const checkLoanStatus = (objectStatusID: number) => {
	return [0, 5, 12, 13].includes(objectStatusID);
};


interface ArtworkFromOneOfFortyDifferentAPIs {
	objectID?: number,
	ObjectID?: number,
	artworkURL?: string
	url?: string

}
