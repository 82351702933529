import { CallOutBox } from "./callOutBox";
import { CollectionPopUp } from "./collection-pop-up";
import {InternalLink, ExternalLink } from "./link";

export const marks = {
	internalLink: InternalLink,
	link: ExternalLink,
	callOutBox: CallOutBox,
	collectionPopUp: CollectionPopUp
}

export default marks;