import { ObjectViewerButton } from 'components/collection/object-viewer-button';
import { fetchObjects } from 'lib/collection/fetchObjects';
export const CollectionPopUp = async (props: IProps) => {
  const {
    children,
    value
  } = props;
  const response = await fetchObjects({
    objectIDs: [value.objectWrapper.objectId]
  });
  const object = response.objects[0];
  if (!children) {
    return null;
  }
  return <ObjectViewerButton text={children} object={object} data-sentry-element="ObjectViewerButton" data-sentry-component="CollectionPopUp" data-sentry-source-file="index.tsx" />;
};
interface IProps {
  children: string;
  value: {
    objectWrapper: {
      objectId: number;
    };
  };
}
export default CollectionPopUp;