import Link from "next/link";
import PropTypes from "prop-types";
import { routeForDocumentWithSubsection } from "lib/routes";
import styles from "./link.module.scss";
const linkClasses = style => {
  if (style === "primary" || style === "secondary") {
    return `${style} button ${styles.button}`;
  } else {
    return null;
  }
};
export const ExternalLink = ({
  value,
  children
}) => {
  const {
    blank,
    href,
    style
  } = value;
  if (!href) {
    return null;
  }
  return <Link className={linkClasses(style)} href={href} target={blank ? "_blank" : "_self"} rel="noreferrer" data-sentry-element="Link" data-sentry-component="ExternalLink" data-sentry-source-file="index.jsx">
			{children}
		</Link>;
};
ExternalLink.propTypes = {
  children: PropTypes.any,
  value: PropTypes.shape({
    blank: PropTypes.bool,
    href: PropTypes.string,
    style: PropTypes.string
  })
};
export const InternalLink = ({
  children,
  value
}) => {
  const {
    link,
    subsection
  } = value;
  if (!link) {
    return null;
  }
  const href = routeForDocumentWithSubsection({
    ...link,
    subsection
  });
  return <Link href={{
    pathname: href
  }} className={linkClasses(link.style)} data-sentry-element="Link" data-sentry-component="InternalLink" data-sentry-source-file="index.jsx">
			{children}
		</Link>;
};
InternalLink.propTypes = {
  children: PropTypes.any,
  value: PropTypes.shape({
    link: PropTypes.shape({
      style: PropTypes.any
    }),
    subsection: PropTypes.any
  })
};