import { PortableText } from '@portabletext/react';
import type { PortableTextBlock } from "@portabletext/types";
import { marks } from "./pt-components/marks";
import list from "./pt-components/list";
import MarkdownParser from "./markdown-parser";
const RichTextInline = ({
  value
}: IProps) => {
  if (!value) {
    return null;
  }
  if (typeof value === "string") {
    return <MarkdownParser>{value}</MarkdownParser>;
  }
  return <PortableText value={value} components={{
    marks: (marks as any),
    list: (list as any)
  }} data-sentry-element="PortableText" data-sentry-component="RichTextInline" data-sentry-source-file="rich-text-inline-only.tsx" />;
};
interface IProps {
  value: PortableTextBlock[] | string;
}
export default RichTextInline;