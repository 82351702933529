import { IArtworkMinimum } from "@metmuseum/types";
import styles from "./object-info-table.module.scss";
import LegacyOrMarkdownParser from "components/rich-text/legacy-or-markdown-parser";
//The data from the collection API is ugly and full of html (<br>s and <i> tags mostly).
const ObjectInfoTable = ({
  object
}: Props) => {
  const {
    title,
    primaryArtist,
    period,
    date,
    culture,
    medium,
    dimensions,
    classification,
    creditLine,
    accessionNumber,
    provenance
  } = object;
  return <div className={styles.grid} data-sentry-component="ObjectInfoTable" data-sentry-source-file="index.tsx">
			{title && <><div className={styles.label}>Title</div> <LegacyOrMarkdownParser>{title}</LegacyOrMarkdownParser></>}
			{primaryArtist && <><div className={styles.label}>Artist</div> <LegacyOrMarkdownParser>{primaryArtist}</LegacyOrMarkdownParser></>}
			{period && <><div className={styles.label}>Period</div> <LegacyOrMarkdownParser>{period}</LegacyOrMarkdownParser></>}
			{date && <><div className={styles.label}>Date</div> <LegacyOrMarkdownParser>{date}</LegacyOrMarkdownParser></>}
			{culture && <><div className={styles.label}>Culture</div> <LegacyOrMarkdownParser>{culture}</LegacyOrMarkdownParser></>}
			{medium && <><div className={styles.label}>Medium</div> <LegacyOrMarkdownParser>{medium}</LegacyOrMarkdownParser></>}
			{dimensions && <><div className={styles.label}>Dimensions</div> <LegacyOrMarkdownParser>{dimensions}</LegacyOrMarkdownParser></>}
			{classification && <><div className={styles.label}>Classification</div> <LegacyOrMarkdownParser>{classification}</LegacyOrMarkdownParser></>}
			{creditLine && <><div className={styles.label}>Credit Line</div> <LegacyOrMarkdownParser>{creditLine}</LegacyOrMarkdownParser></>}
			{accessionNumber && <><div className={styles.label}>Accession No.</div> <LegacyOrMarkdownParser>{accessionNumber}</LegacyOrMarkdownParser></>}
			{provenance && <><div className={styles.label}>Provenance</div> <LegacyOrMarkdownParser>{provenance}</LegacyOrMarkdownParser></>}
		</div>;
};
export default ObjectInfoTable;
interface Props {
  object: IArtworkMinimum;
}